<template>
  <div class="tixian">
    <headbar title="兑换" rightText="兑换记录" @rightClick="rightClick"></headbar>

    <div class="content">
      <div class="title">兑换币种</div>
      <div class="input-wrap" @click="show = true">
        <div class="select-label">{{ select.coinname1 }} 兑换 {{ select.coinname2 }}</div>
      </div>
      <div class="title">数量</div>
      <div class="input-wrap">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          placeholder="请输入数量" @update:model-value="change(select.id)" />
      </div>
      <div class="title">预计获得</div>
      <div class="input-wrap">
        <q-input borderless v-model="dhnum" dense :input-style="{ color: '#fff' }" style="width: 100%;" readonly />
      </div>
      <div class="balance">
        <div style="color: #FFD585;">1DG ≈ {{ dgprice }}USDT</div>
      </div>
      <div class="balance">
        <div style="color: #FFD585;">手续费:{{ 100 - select.bili }}%</div>
      </div>
      <div class="balance" v-if="select.id == 1">
        <div>账户余额：{{ select.jine }} {{ select.coinname1 }}</div>
        <div class="all-btn" @click="allClick(select.id)">全部</div>
      </div>
      <div class="balance" v-else-if="select.id == 2">
        <div>账户余额：{{ select.jine }} {{ select.coinname1 }}</div>
        <div class="all-btn" @click="allClick(select.id)">全部</div>
      </div>
      <div class="m-btn btn" @click="submit">提交</div>
    </div>

    <div class="cp-popup">
      <van-popup v-model:show="show" position="bottom">
        <van-picker confirm-button-text="确实" cancel-button-text="取消" :columns="selectList" @confirm="onConfirm"
          @cancel="show = false" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Picker, Popup } from 'vant';
import CustomComponent from "@/components/CustomComponent";
import Web3Helper from "../../assets/js/web3helper.js";

export default {
  name: '',
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  setup() {
    return {
      cid: ref(""),
      us: ref(null),
      num: ref(""),
      select: ref({}),
      selectList: ref([]),
      show: ref(false),
      dgprice: ref(0),
      dhnum: ref("")
    }
  },
  created() {
    this.cid = this.$route.query.cid
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getSelect()
      this.getDGtoUSDT()
    }
  },
  methods: {
    rightClick() {
      this.$router.push('ZhuanhuanRecord')
    },
    onConfirm(e) {
      this.select = e
      this.show = false
    },
    change(selectId) {
      if (selectId == 1) {
        this.dhnum = this.num * (this.select.bili / 100) * this.dgprice
      } else if (selectId == 2) {
        this.dhnum = this.num * (this.select.bili / 100) / this.dgprice
      }
      this.dhnum = this.dhnum.toFixed(6)
    },
    allClick(selectId) {
      this.num = this.select.jine
      this.change(selectId)
    },
    getDGtoUSDT() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/GetDGtoUSDT",
        {},
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.dgprice = data.dg
          }
        }
      )
    },
    getSelect() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/ZhuanhuanSelect",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let zlist = res.data.data.zlist
          let wlist = res.data.data.wlist
          zlist.forEach(item => {
            let wallet = wlist.find(item2 => item.cid1 == item2.cid)
            item.text = item.coinname1 + "兑换" + item.coinname2
            item.jine = wallet.jine
          })
          _this.selectList = zlist
          if (zlist.length > 0) {
            let select = zlist.find(item => item.cid == _this.cid)
            _this.select = select ? select : zlist[0]
          }
        }
      )
    },
    submit() {
      let _this = this
      if (Object.keys(_this.select).length == 0) {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请选择货币"],
            persistent: true,
          }
        });
        return
      }
      if (this.num == "") {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要兑换吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$i18n.t("兑换中...")
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/WalletsZhuanhuan/Zhuanhuan",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              zhid: _this.select.id,
              jine: _this.num,
              address: _this.us.address,
              signStr,
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [res.data.msg],
                  persistent: true,
                }
              });
              if (res.data.code == 100) {
                _this.num = ""
                _this.dhnum = ""
                _this.getSelect()
              }
            }
          )
        })
      })
    }
  }
}
</script>

<style scoped>
.tixian {
  padding: 10px;
}

.content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 0 15px 10px;
}

.title {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #1B1B1B;
}

.select-label {
  font-size: 18px;
  font-weight: bold;
  color: #e7ba47;
}

.balance {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #aaaaaa;
}

.all-btn {
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #000;
  background-color: #FFD585;
}

.line {
  margin-top: 20px;
  height: 5px;
  /* background: url(~@/assets/img/base/fenge.png) no-repeat; */
  background-size: 100% 100%;
}

.other-cell {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.btn {
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
}
</style>